.intro-and-icon-row-desktop {
    display: flex;
    text-align: center;
    padding-top: 5%;
}
.intro-and-icon-col-desktop {
    display: flex;
    align-items: center;
    margin: auto;
    animation: none;
}
.intro-row-mobile {
    display: none;
}
.icon-row-mobile {
    display: none;
}
@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Raleway:800,500,500italic,900");

.search-icon {
    color: var(--port-gore);
    font-family: 'Font Awesome 5 Free-Solid', Helvetica;
    font-size: 96px;
    letter-spacing: -3.36px;
    margin-left: 7px;
    margin-top: 3px;
    min-height: 110px;
    text-align: center;
    width: 128px;
}
.search-intro-row-mobile {
    display: none;
}

@media only screen and (max-width: 600px) {
    .search-intro-row-mobile {
        display: inline;
    }
    .intro-and-icon-row-desktop {
        display: none;
    }
    .intro-row-mobile {
        display: flex;
        padding-top: 4%;
    }
    .intro-col-mobile {
        display: inline;
        margin: auto;
        width: 100%;
        justify-items: center!important;
        vertical-align: middle!important;
    }
    .icon-row-mobile {
        display: none;
        padding-top: 1.5%;
    }
    .intro-text {
        align-content: center;
    }
    .our-tools-intro {
        align-items: flex-start;
        display: flex;
    }
    @import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
    @import url("https://fonts.googleapis.com/css?family=Raleway:800,500,500italic,900");
    .raleway-black-port-gore-100px {
        color: var(--port-gore);
        font-family: var(--font-family-raleway);
        font-size: 80px;
        font-style: normal;
        font-weight: 900;
    }
    .tools-icon-mobile {
        display: none;
    }
    .search {
        width: 100%;
        font-size: 20vw!important;
        padding-top: 10%;
    }
}
