/* Changelog: Jim - Remove padding-left, add width */
.privacy-contact-footer {
    padding-top: 30px;
    width: 100%;
}
/* Changelog: Jim */
.footer-last-third {
  width: 100%;
}
.contact-us-and-privacy-policy-mobile {
    display: none;
}
.mobile {
    display: none;
}

/* Changelog: Jim */
.footer-logo {
    padding-left: 20px;
    padding-bottom: 20px;
    padding-top: 10px;
    float: left;
    width:100%;
}
/* Changelog: Jim */
.footer-logo a {
  display: flex;

}
.footer-icon {
    height: 100px;
    overflow: hidden;
    align-items: flex-start; 
}


.contact-us {
    letter-spacing: -0.55px;
    min-height: 26px;
    min-width: 136px;
    text-align: center;
}
.contact-us-and-privacy-policy {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    width: auto;
}
.privacy-policy {
    letter-spacing: -0.55px;
    margin-left: 8px;
    min-height: 26px;
    min-width: 169px;
    text-align: center;
    width: auto;
}
.text-19 {
    letter-spacing: -0.55px;
    margin-left: 11px;
    min-height: 26px;
    min-width: 8px;
    text-align: center;
}
.footer-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    width: auto;
    height: auto;
    display: flex;
}
.footer-all {
    background-color: rgba(34, 31, 75, 1);
    height: auto;
    width: auto;
}
.contactus {
    width: auto;
}
.divider {
    width: auto;
    padding-left: 10px;
}
@media only screen and (max-width: 800px) {
    .contact-us-and-privacy-policy-mobile {
        display: flex;
        flex-direction: column;
    }
    .privacy-policy-row {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
    }
    .copyright-row {
        width: 100%;
        display: flex;
    }

    /* Changelog: Jim - padding */
    .footer-all {
        height: 150px;
        width: 100vw;
        padding-top: 20px;
        background-color: rgba(34, 31, 75, 1);
    }
    .footer-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: auto;
        height: auto;
        text-align: left;
    }
    .privacy-contact-footer {
        display: none;
    }
    .privacy-contact-footer-mobile {
        padding-left: 0%;
        padding-top: 100px;
    }

    /* Changelog: Jim - changed to footer-logo from footer-icon */
    .footer-logo {
        display: none;
    }
    /* Changelog: Jim */
    .footer-last-third {
      display: none
    }
    .privacy-policy {
        letter-spacing: -0.55px;
    }
    /* Changelog: Jim */
    .copyright-row {
      justify-content:center;
    }
    .contactus {
        width: 150px;
    }
}
@media only screen and (min-width: 1800px) {
  /* Changelog: JIm - this doesn't make sense, removing */
  /*
    .privacy-contact-footer {
        padding-left: 35vw;
    }
  */
}
