@import url(https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css);
@import url(https://fonts.googleapis.com/css?family=Raleway:800,500,500italic,900);
@import url(https://fonts.googleapis.com/css2?family=Kristi&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css);
@import url(https://fonts.googleapis.com/css?family=Raleway:800,500,500italic,900);
@import url(https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css);
@import url(https://fonts.googleapis.com/css?family=Raleway:800,500,500italic,900);
@import url(https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css);
@import url(https://fonts.googleapis.com/css?family=Raleway:800,500,500italic,900);
@import url(https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css);
@import url(https://fonts.googleapis.com/css?family=Raleway:800,500,500italic,900);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  margin: 0px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
html,body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 2s alternate;
            animation: App-logo-spin infinite 2s alternate;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #6fa5eb;
}
.container {
  width: auto;
  padding: 0;
}
.homepage-row {
  margin: auto
}
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  to {
    -webkit-transform: scaleX(1.12);
            transform: scaleX(1.12);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  to {
    -webkit-transform: scaleX(1.12);
            transform: scaleX(1.12);
  }
}
@media only screen and (max-width: 600px) {
  .container {
    width: auto;
  }
}

* {
  box-sizing: border-box;
}
.fontawesome5free-solid-normal-white-53px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-family: var(--font-family-font_awesome_5_free-solid);
  font-size: 53px;
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 400;
}
.raleway-black-port-gore-72px {
  color: rgba(34, 31, 75, 1);
  color: var(--port-gore);
  font-family: "Raleway", Helvetica;
  font-family: var(--font-family-raleway);
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
}

.kristi-regular-165px{
  color: #FFDB07;
  font-family: 'Kristi', cursive;
  font-style: regular;
  font-size: 165px;
}

.roboto-black-port-gore-48px {
  color: rgba(34, 31, 75, 1);
  color: var(--port-gore);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto);
  font-size: 42px;
  font-style: normal;
  font-weight: 900;
  letter-spacing: -1.5px;
}
.raleway-black-port-gore-84px {
  color: rgba(34, 31, 75, 1);
  color: var(--port-gore);
  font-family: "Raleway", Helvetica;
  font-family: var(--font-family-raleway);
  font-size: 84px;
  font-size: var(--font-size-xxxxxl1);
  font-style: normal;
  font-weight: 900;
}
.raleway-black-port-gore-100px {
  color: rgba(34, 31, 75, 1);
  color: var(--port-gore);
  font-family: "Raleway", Helvetica;
  font-family: var(--font-family-raleway);
  font-size: 100px;
  font-size: var(--font-size-xxxxxl2);
  font-style: normal;
  font-weight: 900;
}
.raleway-white-84px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Raleway", Helvetica;
  font-family: var(--font-family-raleway);
  font-size: 84px;
  font-style: normal;
  font-weight: 900;
}
.raleway-white-100px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Raleway", Helvetica;
  font-family: var(--font-family-raleway);
  font-size: 100px;
  font-size: var(--font-size-xxxxxl2);
  font-style: normal;
  font-weight: 900;
}
.raleway-black-port-gore-24px {
  color: rgba(34, 31, 75, 1);
  color: var(--port-gore);
  font-family: "Raleway", Helvetica;
  font-family: var(--font-family-raleway);
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
}
.raleway-black-port-gore-30px {
  color: rgba(34, 31, 75, 1);
  color: var(--port-gore);
  font-family: "Raleway", Helvetica;
  font-family: var(--font-family-raleway);
  font-size: 30px;
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 900;
}
.roboto-black-port-gore-30px {
  color: rgba(34, 31, 75, 1);
  color: var(--port-gore);
  font-family: "Roboto", Helvetica;
  font-family: var(--font-family-roboto);
  font-size: 22px;
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 5600;
}
.raleway-black-port-gore-32px {
  color: rgba(34, 31, 75, 1);
  color: var(--port-gore);
  font-family: "Raleway", Helvetica;
  font-family: var(--font-family-raleway);
  font-size: 32px;
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 900;
}
.raleway-extra-bold-white-42px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Raleway", Helvetica;
  font-family: var(--font-family-raleway);
  font-size: 42px;
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 800;
}
.raleway-bold-port-gore-42px {
  color: rgba(34, 31, 75, 1);
  color: var(--port-gore);
  font-family: "Raleway", Helvetica;
  font-family: var(--font-family-raleway);
  font-size: 42px;
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 900;
}
.raleway-medium-port-gore-32px {
  color: rgba(34, 31, 75, 1);
  color: var(--port-gore);
  font-family: "Raleway", Helvetica;
  font-family: var(--font-family-raleway);
  font-size: 32px;
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 500;
}
.raleway-white-32px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Raleway", Helvetica;
  font-family: var(--font-family-raleway);
  font-size: 32px;
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 500;
}
.raleway-medium-white-17px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Raleway", Helvetica;
  font-family: var(--font-family-raleway);
  font-size: 17px;
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}
.raleway-medium-port-gore-17px {
  color: rgba(34, 31, 75, 1);
  color: var(--port-gore);
  font-family: "Raleway", Helvetica;
  font-family: var(--font-family-raleway);
  font-size: 17px;
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}
.raleway-medium-white-22px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Raleway", Helvetica;
  font-family: var(--font-family-raleway);
  font-size: 22px;
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}
:root {
  --font-family-font_awesome_5_free-solid: "Font Awesome 5 Free-Solid", Helvetica;
  --font-family-raleway: "Raleway", Helvetica;
  --font-family-roboto: "Roboto", Helvetica;
  --font-size-l: 22px;
  --font-size-m: 17px;
  --font-size-xl: 30px;
  --font-size-xxl: 32px;
  --font-size-xxxl: 42px;
  --font-size-xxxxl: 53px;
  --font-size-xxxxxl1: 84px;
  --font-size-xxxxxl2: 100px;
  --font-size-xxxxxxxxxxxxl: 96px;
  --havelock-blue: rgba(84, 146, 234, 1);
  --porsche: rgba(234, 178, 86, 1);
  --port-gore: rgba(34, 31, 75, 1);
  --white: rgba(255, 255, 255, 1);
}
/* *{border: 10px solid red;} */

/* Changelog: Jim - changed height to 100% */
.landing-page {
  background-image: url(/static/media/bbtbackground-767px.932e1995.jpg);
  background-color: #cccccc;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  width: auto;
  min-height: 431px;
}

.animated-text-row {
  display: inline;
  width: 100%;
}

.kristi-regular-165px{
  color:#FFDB07;
  font-family: 'Kristi', cursive;
  font-style: regular;
  font-size: 100px;
  line-height: 100px;
}

.raleway-extra-bold-white-84px {
  color: var(--white);
  font-family: var(--font-family-raleway);
  font-size: 84px;
  font-style: normal;
  font-weight: bold;
}

.raleway-medium-white-32px {
  width: 800px;
}

/* Yellow Text start */

.yellow-text-one {
  color: #FFDB07;
  font-weight: bold;
}

.yellow-text-two {
  color: #FFDB07;
  font-weight: bold;
}

.yellow-text-three {
  color: #FFDB07;
  font-weight: bold;
}

/* Yellow Text end */

.subtext-row {
  padding-top: 40px;
  -webkit-animation-name: fadeinup;
          animation-name: fadeinup;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}
.subtext-row-2 {
  padding-top: 40px;
  -webkit-animation-name: fadeinup;
          animation-name: fadeinup;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
}
.wheel-text-row {
  padding-top: 150px;
  -webkit-animation-name: fadeinup;
          animation-name: fadeinup;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}
p {
  display: block;
  text-align: left;
}
.button-row {
  padding-top: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  -webkit-animation-name: fadeinup;
          animation-name: fadeinup;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
}
.drop-shadow {
  -webkit-filter: drop-shadow(16px 20px 16px black);
          filter: drop-shadow(16px 20px 16px black);
}
.bb-btn {
  padding: 15px 30px 15px;
  font-size: 22px;
  font-family: var(--font-family-raleway);
  font-weight: bold;
  color: #ffffff;
  background-color: linear-gradient(rgb(170, 148, 23), rgb(223, 205, 48));
  border-radius: 3px;
  background-image: linear-gradient(
    to right,
    #5587e4 0%,
    #3b7cd1 51%,
    #5a89e0 100%
  );
  box-shadow: "0 3px 5px 2px rgba(80,80,80, .3)";
  /* text-transform: uppercase; */
}
.bb-btn:hover {
  background: "#eab256";
  opacity: 0.9;
}
.innovate-col {
  display: -webkit-inline-flex;
  display: inline-flex;
  padding-top: 5%;
}

@media only screen and (max-width: 1220px) {
  .innovate-col {
    display: none;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .col-xl-12 {
    width: 100%;
  }
.landing-page {
  background-image: url(/static/media/bbtbackground-1920px.04d858d0.jpg);
  background-color: #cccccc;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: auto;
  width: auto;
  min-height: 1080px;
}
}

/* X Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399px) {
  .col-xl-12 {
    width: 100%;
  }
.landing-page {
  background-image: url(/static/media/bbtbackground-1399px.a28b42ac.jpg);
  background-color: #cccccc;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: auto;
  width: auto;
  min-height: 787px;
}
}


/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199px) {
  .col-lg-12 {
    width: 100%;
  }
  .landing-page {
    background-image: url(/static/media/bbtbackground-1199px.ef98b0f1.jpg);
    background-color: #cccccc;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: auto;
    width: auto;
    min-height: 674px;
  }
 }

 /* Medium devices (tablets, 768px and up) */
 @media (min-width: 768px) and (max-width: 991px) {
  .col-md-12 {
    width: 100%;
  }
  .landing-page {
    background-image: url(/static/media/bbtbackground-991px.e7ce789f.jpg);
    background-color: #cccccc;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: auto;
    width: auto;
    min-height: 557px;
  }
 }



   /* Small devices (landscape phones, 576px and up) */
   @media (min-width: 576x) and (max-width: 767px) {
    .col-sm-12 {
      width: 100%;
    }
  }

.container-landing {
  display: grid;
  padding: 3px;
  margin: 3px;
  width: 100%;
}

.kristi-regular-165px {
  font-size:  2.0em;
  margin-top: 3px;
 }

.raleway-white-84px {
  font-size:  2.3em;
  margin-top: 3px;
  
  
}
.subtext-1 {
  font-size:  1.1em;
  line-height: 17px;
  margin-top: 2px;
  -webkit-filter: drop-shadow(10px 1px 4px #000000);
          filter: drop-shadow(10px 1px 4px #000000);
  width: 70%;
  
}
  
.subtext-2 {
  font-size:  1.1em;
  line-height: 17x;
  margin-top: 2px;
  -webkit-filter: drop-shadow(10px 1px 4px #000000);
          filter: drop-shadow(10px 1px 4px #000000);
  width: 70%;
  
  }
  
.bb-btn {
  padding: 10px 24px 10px;
  font-size: 15px;
  margin-top: -35px;
  }
  

.animated-text-col:hover {
  cursor: default;
}

.button-col {
  padding-top: 25px;
  padding-bottom: 10px;
  float: left;

}
.landing-page-icon {
  height: 80vh;
  /* margin-right: 800px */
}
/* Changelog: Jim */
.landing-page-text-1 {
  margin-top: 15px;
}
/* Changelog: Jim */
.landing-page-text-2 {
  margin-top: 15px;
  margin-bottom: 30px;
}

.subtext-1 {
  text-align: left;
  padding-top: 2px;
}

.subtext-2 {
  text-align: left;
  padding-top: 2px;
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .container-landing {
    display: grid;
    padding: 50px;
    margin: 50px;
    width: 100%;
  }
  
    .kristi-regular-165px {
      font-size:  2.5em;
      margin-top: 15px;
    
  
    }
    .raleway-white-84px {
      font-size:  5.6em;
      margin-top: 30px;
  
  
  }
  .subtext-1 {
    font-size:  2.2em;
    line-height: 35px;
    margin-top: 90px;
    -webkit-filter: drop-shadow(10px 1px 4px #000000);
            filter: drop-shadow(10px 1px 4px #000000);
    width: 60%;
  
  }
  
  .subtext-2 {
  font-size:  2.2em;
  line-height: 35px;
  margin-top: 70px;
  -webkit-filter: drop-shadow(10px 1px 4px #000000);
          filter: drop-shadow(10px 1px 4px #000000);
  width: 60%;
  
  }
  
  .bb-btn {
    padding: 18px 35px 18px;
    font-size: 30px;
    margin-top: 20px;
  }

 }

 /* X Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399px) {
  
  .container-landing {
    display: grid;
    padding: 20px;
    margin: 20px;
    width: 100%;
  }
  
    .kristi-regular-165px {
      font-size:  2.3em;
      margin-top: 15px;
    
  
    }
    .raleway-white-84px {
      font-size:  4.6em;
      margin-top: 30px;
  
  
  }
  .subtext-1 {
    font-size:  1.9em;
    line-height: 33px;
    margin-top: 60px;
    -webkit-filter: drop-shadow(10px 1px 4px #000000);
            filter: drop-shadow(10px 1px 4px #000000);
    width: 60%;
  
  }
  
  .subtext-2 {
  font-size:  1.9em;
  line-height: 33px;
  margin-top: 40px;
  -webkit-filter: drop-shadow(10px 1px 4px #000000);
          filter: drop-shadow(10px 1px 4px #000000);
  width: 60%;
  
  }
  
  .bb-btn {
    padding: 12px 25px 12px;
    font-size: 25px;
    margin-top: -10px;
  }
  
   }


 /* Large devices (desktops, 992px and up) */
 @media (min-width: 992px) and (max-width: 1199px) {

.container-landing {
  display: grid;
  padding: 10px;
  margin: 10px;
  width: 100%;
}

  .kristi-regular-165px {
    font-size:  2.25em;
    margin-top: 15px;
  

  }
  .raleway-white-84px {
    font-size:  4.5em;
    margin-top: 30px;


}
.subtext-1 {
  font-size:  1.8em;
  line-height: 32px;
  margin-top: 40px;
  -webkit-filter: drop-shadow(10px 1px 4px #000000);
          filter: drop-shadow(10px 1px 4px #000000);
  width: 60%;

}

.subtext-2 {
font-size:  1.8em;
line-height: 32px;
margin-top: 40px;
-webkit-filter: drop-shadow(10px 1px 4px #000000);
        filter: drop-shadow(10px 1px 4px #000000);
width: 60%;

}

.bb-btn {
  padding: 12px 23px 12px;
  font-size: 20px;
  margin-top: -20px;
}

 }

/* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991px) {
  .container-landing {
    display: grid;
    padding: 10px;
    margin: 10px;
    width: 100%;
    }
    
  .kristi-regular-165px {
    font-size:  2em;
    margin-top: 10px;
   }

  .raleway-white-84px {
    font-size:  4em;
    margin-top: 10px;
    
    
  }
  .subtext-1 {
    font-size:  1.6em;
    line-height: 27px;
    margin-top: 20px;
    padding-top: 10px;
    -webkit-filter: drop-shadow(10px 1px 4px #000000);
            filter: drop-shadow(10px 1px 4px #000000);
    width: 63%;
    
  }
    
  .subtext-2 {
    font-size:  1.6em;
    line-height: 27x;
    margin-top: 20px;
    padding-top: 10px;
    -webkit-filter: drop-shadow(10px 1px 4px #000000);
            filter: drop-shadow(10px 1px 4px #000000);
    width: 63%;
    
    }
    
  .bb-btn {
    padding: 10px 24px 10px;
    font-size: 18px;
    margin-top: -5px;
    }
    
     }

.navbar-logo {
    padding: 15px;
}

.navbar-icon {
    height: 100px;
}

.navbar-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border: 1px solid #2c2a2a;
    background-color: rgba(34, 31, 75, 1);
}

li {
    float: left;
}

.login-btn {
    padding-right: 30px;
    padding-top: 45px;
    -webkit-justify-content: end;
            justify-content: end;
    float: right;
}

.menu {
    -webkit-justify-content: right;
            justify-content: right;
}


/* Changelog: Jim - Remove padding-left, add width */
.privacy-contact-footer {
    padding-top: 30px;
    width: 100%;
}
/* Changelog: Jim */
.footer-last-third {
  width: 100%;
}
.contact-us-and-privacy-policy-mobile {
    display: none;
}
.mobile {
    display: none;
}

/* Changelog: Jim */
.footer-logo {
    padding-left: 20px;
    padding-bottom: 20px;
    padding-top: 10px;
    float: left;
    width:100%;
}
/* Changelog: Jim */
.footer-logo a {
  display: -webkit-flex;
  display: flex;

}
.footer-icon {
    height: 100px;
    overflow: hidden;
    -webkit-align-items: flex-start;
            align-items: flex-start; 
}


.contact-us {
    letter-spacing: -0.55px;
    min-height: 26px;
    min-width: 136px;
    text-align: center;
}
.contact-us-and-privacy-policy {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: auto;
}
.privacy-policy {
    letter-spacing: -0.55px;
    margin-left: 8px;
    min-height: 26px;
    min-width: 169px;
    text-align: center;
    width: auto;
}
.text-19 {
    letter-spacing: -0.55px;
    margin-left: 11px;
    min-height: 26px;
    min-width: 8px;
    text-align: center;
}
.footer-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    width: auto;
    height: auto;
    display: -webkit-flex;
    display: flex;
}
.footer-all {
    background-color: rgba(34, 31, 75, 1);
    height: auto;
    width: auto;
}
.contactus {
    width: auto;
}
.divider {
    width: auto;
    padding-left: 10px;
}
@media only screen and (max-width: 800px) {
    .contact-us-and-privacy-policy-mobile {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .privacy-policy-row {
        width: 100%;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-evenly;
                justify-content: space-evenly;
    }
    .copyright-row {
        width: 100%;
        display: -webkit-flex;
        display: flex;
    }

    /* Changelog: Jim - padding */
    .footer-all {
        height: 150px;
        width: 100vw;
        padding-top: 20px;
        background-color: rgba(34, 31, 75, 1);
    }
    .footer-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: auto;
        height: auto;
        text-align: left;
    }
    .privacy-contact-footer {
        display: none;
    }
    .privacy-contact-footer-mobile {
        padding-left: 0%;
        padding-top: 100px;
    }

    /* Changelog: Jim - changed to footer-logo from footer-icon */
    .footer-logo {
        display: none;
    }
    /* Changelog: Jim */
    .footer-last-third {
      display: none
    }
    .privacy-policy {
        letter-spacing: -0.55px;
    }
    /* Changelog: Jim */
    .copyright-row {
      -webkit-justify-content:center;
              justify-content:center;
    }
    .contactus {
        width: 150px;
    }
}
@media only screen and (min-width: 1800px) {
  /* Changelog: JIm - this doesn't make sense, removing */
  /*
    .privacy-contact-footer {
        padding-left: 35vw;
    }
  */
}

.all-blocks {
  margin: auto;
  margin-top: 5%;
  padding-bottom: 10%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.analyze-news {
  letter-spacing: 0.00px;
  margin-left: 2.0px;
  margin-top: 14px;
  min-height: 20px;
  text-align: center;
  width: 391px;
}
.build-blue-tools-logo-footer {
  -webkit-align-self: flex-start;
          align-self: flex-start;
  height: 148px;
  object-fit: cover;
  width: 137px;
}
.build-blue-tools-logo-header {
  height: 221px;
  object-fit: cover;
  width: 205px;
}
.campaign-staff {
  letter-spacing: 0.00px;
  margin-left: 2.0px;
  margin-top: 22px;
  min-height: 20px;
  text-align: center;
  width: 293px;
}
.conduct-a-poll {
  letter-spacing: 0.00px;
  margin-left: 2.0px;
  margin-top: 14px;
  min-height: 20px;
  text-align: center;
  width: 323px;
}
.copyright {
  letter-spacing: -0.55px;
  margin-right: 12.0px;
  margin-top: 28px;
  min-height: 26px;
  min-width: 232px;
  text-align: center;
}
.create-graphics {
  letter-spacing: 0.00px;
  margin-left: 2.0px;
  margin-top: 14px;
  min-height: 20px;
  text-align: center;
  width: 303px;
}
.data-research {
  letter-spacing: -1.47px;
  margin-left: 2.0px;
  margin-top: 18px;
  min-height: 49px;
  text-align: center;
  width: 409px;
}
.data-research-block {
  -webkit-align-items: center;
          align-items: center;
  background-color: var(--havelock-blue);
  border-radius: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 263px;
  margin-left: 1px;
  margin-top: 20px;
  overflow: hidden;
  padding: 22px 0;
  width: 407px;
}
.digital-ads {
  letter-spacing: -1.47px;
  margin-left: 3.0px;
  margin-top: 18px;
  min-height: 49px;
  text-align: center;
  width: 372px;
}
.digital-ads-block {
  -webkit-align-items: center;
          align-items: center;
  background-color: var(--havelock-blue);
  border-radius: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 265px;
  margin-top: 20px;
  overflow: hidden;
  padding: 22px 0;
  width: 407px;
}
.digital-ads-icon {
  letter-spacing: -1.85px;
  margin-left: 2.0px;
  min-height: 61px;
  text-align: center;
  width: 267px;
}
.digital-content {
  letter-spacing: -1.47px;
  margin-left: 2.0px;
  margin-top: 18px;
  min-height: 49px;
  text-align: center;
  width: 401px;
}
.digital-content-block {
  -webkit-align-items: center;
          align-items: center;
  background-color: var(--port-gore);
  border-radius: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 299px;
  margin-left: 1px;
  overflow: hidden;
  padding: 22px 2px;
  width: 407px;
}
.digital-testing {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  letter-spacing: 0.00px;
  margin-top: 22px;
  min-height: 20px;
  text-align: center;
  width: 355px;
}
.e-mail {
  letter-spacing: -1.47px;
  margin-left: 1.0px;
  margin-top: 23px;
  min-height: 49px;
  text-align: center;
  width: auto;
}
.e-mail-block {
  -webkit-align-items: center;
          align-items: center;
  background-color: var(--port-gore);
  border-radius: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 335px;
  overflow: hidden;
  padding: 21px 8px;
  width: 407px;
}
.e-mail-testing {
  letter-spacing: 0.00px;
  margin-left: 3.0px;
  margin-top: 14px;
  min-height: 20px;
  text-align: center;
  width: 326px;
}
.find-talent {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  letter-spacing: -1.47px;
  margin-top: 18px;
  min-height: 49px;
  text-align: center;
  width: 360px;
}
.find-talent-block {
  -webkit-align-items: center;
          align-items: center;
  background-color: var(--port-gore);
  border-radius: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 330px;
  margin-top: 22px;
  overflow: hidden;
  padding: 22px;
  width: 407px;
}
.flex-col-1 {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 450px;
  -webkit-animation-name: fadeinup;
          animation-name: fadeinup;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}
.flex-col-2 {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0;
  width: 450px;
  -webkit-animation-name: fadeinup;
          animation-name: fadeinup;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
}
.flex-col {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0;
  width: 450px;
  -webkit-animation-name: fadeinup;
          animation-name: fadeinup;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
}
.footer {
  -webkit-align-items: center;
          align-items: center;
  background-color: var(--port-gore);
  display: -webkit-flex;
  display: flex;
  height: 200px;
  margin-right: 1.0px;
  margin-top: 102px;
  padding: 20px 86px;
  width: 1441px;
}
.footer-button {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  display: -webkit-flex;
  display: flex;
  margin-left: 138px;
  margin-top: 3.0px;
  min-width: 314px;
}
.fundraising {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  letter-spacing: -1.47px;
  margin-top: 18px;
  min-height: 49px;
  text-align: center;
  width: 372px;
}
.fundraising-block {
  -webkit-align-items: center;
          align-items: center;
  background-color: var(--havelock-blue);
  border-radius: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 265px;
  margin-top: 19px;
  overflow: hidden;
  padding: 22px 16px;
  width: 407px;
}
.fundraising-icon {
  letter-spacing: -1.85px;
  margin-left: 2.0px;
  min-height: 61px;
  text-align: center;
  width: 205px;
}
.grow-my-list {
  letter-spacing: 0.00px;
  margin-left: 1.0px;
  margin-top: 23px;
  min-height: 20px;
  text-align: center;
  width: 304px;
}
.header {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  background-color: var(--port-gore);
  display: -webkit-flex;
  display: flex;
  height: 262px;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  min-width: 1440px;
  padding: 16px 89px;
}
.header-button {
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-self: center;
          align-self: center;
  background-color: var(--porsche);
  border-radius: 8px;
  display: -webkit-flex;
  display: flex;
  height: 82px;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-left: 705px;
  min-width: 350px;
  padding: 0 36px;
}
.homepage {
  padding: 0%;
}
.icon {
  letter-spacing: -1.85px;
  margin-left: 1.0px;
  min-height: 61px;
  text-align: center;
  width: 190px;
}
.learn-more {
  letter-spacing: -1.05px;
  min-height: 35px;
  min-width: 185px;
  text-align: center;
}
.login-or-sign-up {
  letter-spacing: -1.12px;
  min-height: 38px;
  min-width: 276px;
  text-align: center;
}
.mobilization {
  letter-spacing: -1.47px;
  margin-left: 2.0px;
  margin-top: 18px;
  min-height: 49px;
  text-align: center;
  width: 409px;
}
.mobilization-block {
  -webkit-align-items: center;
          align-items: center;
  background-color: var(--port-gore);
  border-radius: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 301px;
  margin-top: 20px;
  overflow: hidden;
  padding: 22px 0;
  width: 407px;
}
.mobilization-icon {
  letter-spacing: -1.85px;
  margin-left: 2.0px;
  min-height: 61px;
  text-align: center;
  width: 291px;
}
.overlap-group {
  -webkit-align-items: center;
          align-items: center;
  background-color: var(--porsche);
  border-radius: 8px;
  display: -webkit-flex;
  display: flex;
  height: 77px;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  min-width: 312px;
  padding: 0 62px;
}
.paint-brush-icon {
  letter-spacing: -1.85px;
  margin-left: 2.0px;
  min-height: 61px;
  text-align: center;
  width: 231px;
}
.phones {
  letter-spacing: -1.47px;
  margin-left: 2.0px;
  margin-top: 18px;
  min-height: 49px;
  text-align: center;
  width: 313px;
}
.phones-block {
  -webkit-align-items: center;
          align-items: center;
  background-color: var(--havelock-blue);
  border-radius: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 263px;
  overflow: hidden;
  padding: 22px 0;
  width: 407px;
}
.phones-icon {
  letter-spacing: -1.85px;
  min-height: 61px;
  text-align: center;
  width: 181px;
}
.run-calltime {
  letter-spacing: 0.00px;
  margin-left: 3.0px;
  margin-top: 22px;
  min-height: 20px;
  text-align: center;
  width: 322px;
}
.talent-icon {
  letter-spacing: -1.85px;
  margin-left: 2.0px;
  min-height: 61px;
  text-align: center;
  width: 293px;
}
.testing {
  letter-spacing: -1.47px;
  margin-left: 3.0px;
  margin-top: 18px;
  min-height: 49px;
  text-align: center;
  width: 328px;
}
.testing-block {
  -webkit-align-items: center;
          align-items: center;
  background-color: var(--port-gore);
  border-radius: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 260px;
  margin-top: 22px;
  overflow: hidden;
  padding: 22px 25px;
  width: 407px;
}
.testing-icon {
  letter-spacing: -1.85px;
  margin-left: 3.0px;
  min-height: 61px;
  text-align: center;
  width: 270px;
}
.text- {
  letter-spacing: 0.00px;
  margin-top: 15px;
  min-height: 20px;
  text-align: center;
  width: 357px;
}
.text-2-mobile {
  display: none;
}
.text-1-1 {
  letter-spacing: 0.00px;
  margin-left: 2.0px;
  margin-top: 14px;
  min-height: 20px;
  text-align: center;
  width: 409px;
}
.text-10 {
  letter-spacing: 0.00px;
  margin-left: 2.0px;
  margin-top: 14px;
  min-height: 20px;
  text-align: center;
  width: 331px;
}
.text-11 {
  letter-spacing: 0.00px;
  margin-left: 2.0px;
  margin-top: 14px;
  min-height: 20px;
  text-align: center;
  width: 351px;
}
.text-12 {
  letter-spacing: -1.85px;
  margin-left: 2.0px;
  min-height: 61px;
  text-align: center;
  width: 289px;
}
.text-13 {
  letter-spacing: 0.00px;
  margin-left: 2.0px;
  margin-top: 22px;
  min-height: 20px;
  text-align: center;
  width: 357px;
}
.text-14 {
  letter-spacing: 0.00px;
  margin-left: 2.0px;
  margin-top: 22px;
  min-height: 20px;
  text-align: center;
  width: 391px;
}
.text-16 {
  letter-spacing: 0.00px;
  margin-right: 5.0px;
  margin-top: 22px;
  min-height: 20px;
  text-align: center;
  width: 402px;
}
.text-17 {
  letter-spacing: 0.00px;
  margin-right: 5.0px;
  margin-top: 14px;
  min-height: 20px;
  text-align: center;
  width: 402px;
}
.text-2 {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  letter-spacing: 0.00px;
  margin-top: 15px;
  min-height: 20px;
  text-align: center;
  width: 390px;
}
.text-4 {
  letter-spacing: 0.00px;
  margin-left: 1.0px;
  margin-top: 16px;
  min-height: 20px;
  text-align: center;
  width: 374px;
}
.text-6 {
  letter-spacing: 0.00px;
  margin-left: 2.0px;
  margin-top: 22px;
  min-height: 20px;
  text-align: center;
  width: 321px;
}
.text-7 {
  letter-spacing: 0.00px;
  margin-top: 14px;
  min-height: 20px;
  text-align: center;
  width: 345px;
}
.text-8 {
  letter-spacing: 0.00px;
  margin-left: 2.0px;
  margin-top: 22px;
  min-height: 20px;
  text-align: center;
  width: 331px;
}
.text-9 {
  letter-spacing: 0.00px;
  margin-left: 2.0px;
  margin-top: 14px;
  min-height: 20px;
  text-align: center;
  width: 353px;
}
.search-field-home {
  margin-top: 50px;
  width: 800px;
  -webkit-animation-name: fadeinup;
          animation-name: fadeinup;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

span:hover {
  cursor:pointer;
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  .all-blocks {
    -webkit-flex-direction: column;
            flex-direction: column;
    width: auto;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .e-mail-block {
    width: 90%;
  }
  .fundraising-block {
    width: 90%;
  }
  .fundraising {
    -webkit-align-self: flex-end;
            align-self: flex-end;
    letter-spacing: -1.47px;
    min-height: 49px;
    text-align: center;
    width: 300px;
  }
  .find-talent {
    width: 280px;
  }
  .testing-block {
    width: 90%;
  }
  .phones-block {
    width: 90%;
  }
  .find-talent-block {
    width: 90%;
  }
  .digital-ads-block {
    width: 90%;
  }
  .digital-content-block {
    width: 90%;
  }
  .digital-content {
    width: 90%;
    margin-bottom: 0%;
  }
  .digital-testing {
    width: 100%;
  }
  .data-research-block {
    width: 90%;
    height: 280px;
  }
  .data-research {
    width: 280px;
    margin-bottom: 8%;
  }
  .mobilization-block {
    width: 90%;
  }
  .flex-col {
    padding-left: 5%;
    padding-top: 4%;
    padding-right: 0;
    width: auto;
  }
  .flex-col-1 {
    padding-left: 5%;
    padding-right: 0;
    width: auto;
  }
  .flex-col-2 {
    padding-left: 5%;
    padding-top: 4%;
    padding-right: 0;
    width: auto;
  }
  .search-field-home {
    margin-top: 50px;
    width: 90vw;
    -webkit-animation-name: fadeinup;
            animation-name: fadeinup;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
  }
  .text-10 {
    width: 80%;
  }
}
@-webkit-keyframes fadeinup {
  from {
    -webkit-transform: translateY(500px);
            transform: translateY(500px);
    opacity: .1;
  }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes fadeinup {
  from {
    -webkit-transform: translateY(500px);
            transform: translateY(500px);
    opacity: .1;
  }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
  }
}

.intro-and-icon-row-desktop {
    display: -webkit-flex;
    display: flex;
    text-align: center;
    padding-top: 5%;
}
.intro-and-icon-col-desktop {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin: auto;
    -webkit-animation-name: wipe;
            animation-name: wipe;
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
}
.intro-row-mobile {
    display: none;
}
.icon-row-mobile {
    display: none;
}
.raleway-black-port-gore-100px {
    color: var(--port-gore);
    font-family: var(--font-family-raleway);
    font-size: 100px;
    font-style: normal;
    font-weight: 900;
    -webkit-animation-name: reveal;
            animation-name: reveal;
    -webkit-animation-duration: 4.5s;
            animation-duration: 4.5s;
}
.span0-row {
    padding-top: 2%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-animation-name: wipe;
            animation-name: wipe;
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
}
.span0-col {
    text-align: center;
}
.span0 {
    color: rgba(34,31,75,1.0);
    font-family: 'Roboto', Helvetica;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: -0.36px;;
}
.tools-icon {
    color: var(--port-gore);
    font-family: 'Font Awesome 5 Free-Solid', Helvetica;
    font-size: 96px;
    letter-spacing: -3.36px;
    margin-left: 7px;
    margin-top: 3px;
    min-height: 110px;
    text-align: center;
    width: 128px;
    -webkit-animation-name: drop;
            animation-name: drop;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-delay: 4.5s;
            animation-delay: 4.5s;
}


@media only screen and (max-width: 600px) {
    .intro-and-icon-row-desktop {
        display: none;
    }
    .intro-row-mobile {
        display: inline;
        padding-top: 4%;
        
    }
    .intro-col-mobile {
        margin: auto;
        display: inline;
        width: 80vw;
    }
    .icon-row-mobile {
        display: inline;
        padding-top: 1.5%;
    }
    .intro-text {
        -webkit-align-content: center;
                align-content: center;
    }
    .our-tools-intro {
        -webkit-align-items: flex-start;
                align-items: flex-start;
        display: -webkit-flex;
        display: flex;
    }
    .span0-row {
        padding-top: 0%;
        margin: 0%;
    }
    .span0-col {
        width: 360px;
    }
    .span0 {
        color: rgba(34,31,75,1.0);
        font-family: 'Raleway', Helvetica;
        font-size: 24px;
        font-weight: 500;
        letter-spacing: -0.36px;
        text-align: center;
    }
    .title {
        letter-spacing: -3.30px;
        /* min-height: 124px; */
        /* text-align: center; */
    }
    .text-1 {
        /* letter-spacing: -1.12px;
        margin-top: 20px;
        margin-left: 5vw;
        min-height: 69px;
        text-align: center;
        width: 50vw; */
    }
    .tools-icon-mobile {
        color: var(--port-gore);
        font-family: 'Font Awesome 5 Free-Solid', Helvetica;
        font-size: 68px;
        letter-spacing: -3.36px;
        margin: auto;
        padding-top: 1%;
        min-height: 110px;
        text-align: center;
        width: 128px;
    }
    @import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
    @import url("https://fonts.googleapis.com/css?family=Raleway:800,500,500italic,900");
    .raleway-black-port-gore-100px {
        color: var(--port-gore);
        font-family: var(--font-family-raleway);
        font-size: 80px;
        font-style: normal;
        font-weight: 900;
    }
}
@-webkit-keyframes drop {
    0%, 20%, 50%, 80%, 90% {-webkit-transform: translateY(0);transform: translateY(0);} 
   40% {-webkit-transform: translateY(-40px);transform: translateY(-40px);} 
   60% {-webkit-transform: translateY(-30px);transform: translateY(-30px);}
}
@keyframes drop {
    0%, 20%, 50%, 80%, 90% {-webkit-transform: translateY(0);transform: translateY(0);} 
   40% {-webkit-transform: translateY(-40px);transform: translateY(-40px);} 
   60% {-webkit-transform: translateY(-30px);transform: translateY(-30px);}
}
@-webkit-keyframes wipe {
    from {
        opacity: 0;
    }
}
@keyframes wipe {
    from {
        opacity: 0;
    }
}


.results {
    margin: 5%;
    min-height: 1200px;
}
.result-card {
    padding-top: 15px;
}
.result-text {
    margin-top: 15px;
}
.search-field {
    margin-top: 50px;
    width: 800px;
    -webkit-animation: none;
            animation: none;
}

@media only screen and (max-width: 600px) {
    .results {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
    }
    .search-field {
        margin-top: 50px;
        width: 80vw;
        -webkit-animation: none;
                animation: none;
    }
    .result-text {
        margin-bottom: 10%;
    }
}
.vendorbox {
    width: 380px;
    height: 320px;
    /* margin-bottom: 1000px; */
}
.tagline {
    text-align: center;
    overflow-y: auto;
}
.card-img {
    width: 100%;
    object-fit: fill;
    vertical-align: center;
}
.img-container {
    display: relative;
    margin: auto;
    width: auto;
    height: 150px;
}
@media only screen and (max-width: 600px) {
    .vendorbox {
        width: 80vw;
        /* margin-bottom: 1000px; */
    }
}
.modal {
    display: 'flex';
    -webkit-align-items: 'center';
            align-items: 'center'; 
    -webkit-justify-content: 'center'; 
            justify-content: 'center';
}
.paper {
    background-color: white;
    border-radius: 25px;
    width: 70vh;
    border: 2px solid #221f4b;
    box-shadow: 0;
    padding: 2, 4, 3;
}
.banner {
    width: 100%;
    display: block;
    text-align: center;
}
.intro-and-icon-row-desktop {
    display: -webkit-flex;
    display: flex;
    text-align: center;
    padding-top: 5%;
}
.intro-and-icon-col-desktop {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin: auto;
    -webkit-animation: none;
            animation: none;
}
.intro-row-mobile {
    display: none;
}
.icon-row-mobile {
    display: none;
}

.search-icon {
    color: var(--port-gore);
    font-family: 'Font Awesome 5 Free-Solid', Helvetica;
    font-size: 96px;
    letter-spacing: -3.36px;
    margin-left: 7px;
    margin-top: 3px;
    min-height: 110px;
    text-align: center;
    width: 128px;
}
.search-intro-row-mobile {
    display: none;
}

@media only screen and (max-width: 600px) {
    .search-intro-row-mobile {
        display: inline;
    }
    .intro-and-icon-row-desktop {
        display: none;
    }
    .intro-row-mobile {
        display: -webkit-flex;
        display: flex;
        padding-top: 4%;
    }
    .intro-col-mobile {
        display: inline;
        margin: auto;
        width: 100%;
        justify-items: center!important;
        vertical-align: middle!important;
    }
    .icon-row-mobile {
        display: none;
        padding-top: 1.5%;
    }
    .intro-text {
        -webkit-align-content: center;
                align-content: center;
    }
    .our-tools-intro {
        -webkit-align-items: flex-start;
                align-items: flex-start;
        display: -webkit-flex;
        display: flex;
    }
    @import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
    @import url("https://fonts.googleapis.com/css?family=Raleway:800,500,500italic,900");
    .raleway-black-port-gore-100px {
        color: var(--port-gore);
        font-family: var(--font-family-raleway);
        font-size: 80px;
        font-style: normal;
        font-weight: 900;
    }
    .tools-icon-mobile {
        display: none;
    }
    .search {
        width: 100%;
        font-size: 20vw!important;
        padding-top: 10%;
    }
}

.category-page {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.results {
    margin: 5%;
    height: auto;
}
.results-single {
    margin: 5%;
    height: 80vh;
}
.result-card {
    padding-top: 15px;
}
.result-icon {
    height: 340px
}
.result-text {
    margin-top: 15px;
}
@media only screen and (max-width: 600px) {
    .results {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
    }
    .results-single {
        padding-left: 5%;
    }
    .result-card {
        position: relative;
        top: 0;
        right: 0;
    }
    .result-icon {
        display: none;
    }
}
.intro-and-icon-row-desktop {
    display: -webkit-flex;
    display: flex;
    text-align: center;
    padding-top: 5%;
}
.intro-and-icon-col-desktop {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin: auto;
    -webkit-animation: none;
            animation: none;
}
.intro-row-mobile {
    display: none;
}
.icon-row-mobile {
    display: none;
}

.search-icon {
    color: var(--port-gore);
    font-family: 'Font Awesome 5 Free-Solid', Helvetica;
    font-size: 96px;
    letter-spacing: -3.36px;
    margin-left: 7px;
    margin-top: 3px;
    min-height: 110px;
    text-align: center;
    width: 128px;
}
.sub-text {
    margin-top: 15px;
}
.sub-text-mobile {
    display: none;
}
@media only screen and (max-width: 600px) {
    .intro-and-icon-row-desktop {
        display: none;
    }
    .intro-row-mobile {
        display: inline;
        -webkit-align-content: center!important;
                align-content: center!important;
        margin-top: 8%;
    }
    .category-col-mobile {
        width: 100%!important;
    }
    .copy {
        display: none;
    }
    .icon-row-mobile {
        display: inline;
        padding-top: 1.5%;
    }
    .intro-text {
        -webkit-align-content: center;
                align-content: center;
    }
    .sub-text {
        display: none;
    }
    .sub-text-mobile {
        position: relative;
        width: 100%;
        display: inline;
        text-align: center;
    }
    .category-title {
        display: inline;
        font-size: 15vw;
        -webkit-align-self: flex-end;
                align-self: flex-end;
        letter-spacing: -1.47px;
        min-height: 49px;
        text-align: center;
        width: 80vw;
    }
    .fundraising-mobile {
        width: 100%;
        font-size: 14vw!important;
        -webkit-align-self: center;
                align-self: center;
    }
    .mobilization-mobile {
        width: 100%;
        font-size: 13vw!important;
        -webkit-align-self: center;
                align-self: center;
    }
}

.intro-and-icon-row-desktop {
    display: -webkit-flex;
    display: flex;
    text-align: center;
    padding-top: 5%;
}
.intro-and-icon-col-desktop {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin: auto;
    -webkit-animation: none;
            animation: none;
}
.intro-row-mobile {
    display: none;
}
.icon-row-mobile {
    display: none;
}

.search-icon {
    color: var(--port-gore);
    font-family: 'Font Awesome 5 Free-Solid', Helvetica;
    font-size: 96px;
    letter-spacing: -3.36px;
    margin-left: 7px;
    margin-top: 3px;
    min-height: 110px;
    text-align: center;
    width: 128px;
}


@media only screen and (max-width: 600px) {
    .intro-and-icon-row-desktop {
        display: none;
    }
    .intro-row-mobile {
        display: -webkit-flex;
        display: flex;
        padding-top: 4%;
    }
    .intro-col-mobile {
        margin: auto;
        width: 250px;
    }
    .icon-row-mobile {
        display: -webkit-flex;
        display: flex;
        padding-top: 1.5%;
    }
    .intro-text {
        -webkit-align-content: center;
                align-content: center;
    }
    .our-tools-intro {
        -webkit-align-items: flex-start;
                align-items: flex-start;
        display: -webkit-flex;
        display: flex;
    }
    @import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
    @import url("https://fonts.googleapis.com/css?family=Raleway:800,500,500italic,900");
}

.contact-us {
   height: 1400px; 
}
.name-row {
    padding-inline: 12%;
}
.firstname-field {
    width: 100%
}
.lastname-field {
    width: 100%
}
.email-row {
    padding-top: 4%;
    padding-inline: 12%;
}
.email-field {
    width: 100%;
}
.organization-field {
    width: 100%;
}
.phone-field {
    position: relative; 
    top: -16px 
}
.subject-row {
    padding-top: 4%;
    width: 100%;
}
.subject-field {
    width: 50%;
}
.message-row {
    padding-top: 1%;
    width: 100%;
    height: 300px;
}
.message-field {
    width: 50%;
    height: 100%;
}
.submit-row {
    width: 100%;
    padding-top: 2%;
}
.submit-btn {
    height: 50px;
    width: 50%;
    font-size: 18px;
    font-family: var(--font-family-raleway);
    color:#ffffff;
    background-color: linear-gradient(rgb(34, 108, 204), rgb(223, 205, 48));
    border-radius: 20px;
    background-image: linear-gradient(to right, #5e91dd 0%, #3f95ce 51%, #5e91dd  100%);
    box-shadow: '0 3px 5px 2px rgba(80,80,80, .3)',
}
.submission-status {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: auto;
}
.raleway-medium-port-gore-17px {
    position: relative;
}
.line {
    width: 70vw;
}
@media only screen and (max-width: 800px) {
    .firstname-field {
        width: 100%;
        top: -13px 
    }
    .phone-field {
        position: relative; 
        top: 20px 
    }
    .email-row {
        padding-top: 6%;
        padding-inline: 12%;
    }
    .email-field {
        width: 100%;
        top: -12px 
    }
    .submit-row {
        width: 100%;
        padding-top: 5%;
    }
    .submission-status {
        display: none;
    }
    .subject-row {
        padding-top: 10%;
        width: 100%;
    }
    .subject-field {
        width: 75%;
        left: 10px;
    }
    .message-field {
        width: 75%;
        height: 100%;
        left: 10px;
    }
    .contact-us {
        height: 1000px; 
     }
}

.loading {
    display: -webkit-flex;
    display: flex;
    width: auto;
    height: 80vh;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
}
.progress {
    position: absolute;
    top: 50%;
    background-color:transparent;
}
.policy-text-row {
    padding-left: 10vw;
    padding-right: 13vw;
    text-align: left;
}
ul {
    padding-left: 30px;
    display: block;
}
.privacy-li {
    margin-bottom: 8px;
    padding-left: 10px;
    display: list-item;
    float: none;
    list-style-type: square;
}
ol {
    margin-left: 2vw;
}
