.category-page {
    height: fit-content;
}
.results {
    margin: 5%;
    height: auto;
}
.results-single {
    margin: 5%;
    height: 80vh;
}
.result-card {
    padding-top: 15px;
}
.result-icon {
    height: 340px
}
.result-text {
    margin-top: 15px;
}
@media only screen and (max-width: 600px) {
    .results {
        flex-direction: column;
        justify-content: flex-start;
    }
    .results-single {
        padding-left: 5%;
    }
    .result-card {
        position: relative;
        top: 0;
        right: 0;
    }
    .result-icon {
        display: none;
    }
}