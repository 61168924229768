.App {
  text-align: center;
  margin: 0px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
html,body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 2s alternate;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #6fa5eb;
}
.container {
  width: auto;
  padding: 0;
}
.homepage-row {
  margin: auto
}
@keyframes App-logo-spin {
  from {
    transform: scaleX(1);
  }
  to {
    transform: scaleX(1.12);
  }
}
@media only screen and (max-width: 600px) {
  .container {
    width: auto;
  }
}
@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Raleway:800,500,500italic,900");
@import url("https://fonts.googleapis.com/css2?family=Kristi&display=swap");

* {
  box-sizing: border-box;
}
.fontawesome5free-solid-normal-white-53px {
  color: var(--white);
  font-family: var(--font-family-font_awesome_5_free-solid);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 400;
}
.raleway-black-port-gore-72px {
  color: var(--port-gore);
  font-family: var(--font-family-raleway);
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
}

.kristi-regular-165px{
  color: #FFDB07;
  font-family: 'Kristi', cursive;
  font-style: regular;
  font-size: 165px;
}

.roboto-black-port-gore-48px {
  color: var(--port-gore);
  font-family: var(--font-family-roboto);
  font-size: 42px;
  font-style: normal;
  font-weight: 900;
  letter-spacing: -1.5px;
}
.raleway-black-port-gore-84px {
  color: var(--port-gore);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-xxxxxl1);
  font-style: normal;
  font-weight: 900;
}
.raleway-black-port-gore-100px {
  color: var(--port-gore);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-xxxxxl2);
  font-style: normal;
  font-weight: 900;
}
.raleway-white-84px {
  color: var(--white);
  font-family: var(--font-family-raleway);
  font-size: 84px;
  font-style: normal;
  font-weight: 900;
}
.raleway-white-100px {
  color: var(--white);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-xxxxxl2);
  font-style: normal;
  font-weight: 900;
}
.raleway-black-port-gore-24px {
  color: var(--port-gore);
  font-family: var(--font-family-raleway);
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
}
.raleway-black-port-gore-30px {
  color: var(--port-gore);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 900;
}
.roboto-black-port-gore-30px {
  color: var(--port-gore);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 5600;
}
.raleway-black-port-gore-32px {
  color: var(--port-gore);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 900;
}
.raleway-extra-bold-white-42px {
  color: var(--white);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 800;
}
.raleway-bold-port-gore-42px {
  color: var(--port-gore);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 900;
}
.raleway-medium-port-gore-32px {
  color: var(--port-gore);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 500;
}
.raleway-white-32px {
  color: var(--white);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 500;
}
.raleway-medium-white-17px {
  color: var(--white);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}
.raleway-medium-port-gore-17px {
  color: var(--port-gore);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}
.raleway-medium-white-22px {
  color: var(--white);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}
:root {
  --font-family-font_awesome_5_free-solid: "Font Awesome 5 Free-Solid", Helvetica;
  --font-family-raleway: "Raleway", Helvetica;
  --font-family-roboto: "Roboto", Helvetica;
  --font-size-l: 22px;
  --font-size-m: 17px;
  --font-size-xl: 30px;
  --font-size-xxl: 32px;
  --font-size-xxxl: 42px;
  --font-size-xxxxl: 53px;
  --font-size-xxxxxl1: 84px;
  --font-size-xxxxxl2: 100px;
  --font-size-xxxxxxxxxxxxl: 96px;
  --havelock-blue: rgba(84, 146, 234, 1);
  --porsche: rgba(234, 178, 86, 1);
  --port-gore: rgba(34, 31, 75, 1);
  --white: rgba(255, 255, 255, 1);
}
/* *{border: 10px solid red;} */
