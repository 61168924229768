.contact-us {
   height: 1400px; 
}
.name-row {
    padding-inline: 12%;
}
.firstname-field {
    width: 100%
}
.lastname-field {
    width: 100%
}
.email-row {
    padding-top: 4%;
    padding-inline: 12%;
}
.email-field {
    width: 100%;
}
.organization-field {
    width: 100%;
}
.phone-field {
    position: relative; 
    top: -16px 
}
.subject-row {
    padding-top: 4%;
    width: 100%;
}
.subject-field {
    width: 50%;
}
.message-row {
    padding-top: 1%;
    width: 100%;
    height: 300px;
}
.message-field {
    width: 50%;
    height: 100%;
}
.submit-row {
    width: 100%;
    padding-top: 2%;
}
.submit-btn {
    height: 50px;
    width: 50%;
    font-size: 18px;
    font-family: var(--font-family-raleway);
    color:#ffffff;
    background-color: linear-gradient(rgb(34, 108, 204), rgb(223, 205, 48));
    border-radius: 20px;
    background-image: linear-gradient(to right, #5e91dd 0%, #3f95ce 51%, #5e91dd  100%);
    box-shadow: '0 3px 5px 2px rgba(80,80,80, .3)',
}
.submission-status {
    display: flex;
    justify-content: center;
    width: auto;
}
.raleway-medium-port-gore-17px {
    position: relative;
}
.line {
    width: 70vw;
}
@media only screen and (max-width: 800px) {
    .firstname-field {
        width: 100%;
        top: -13px 
    }
    .phone-field {
        position: relative; 
        top: 20px 
    }
    .email-row {
        padding-top: 6%;
        padding-inline: 12%;
    }
    .email-field {
        width: 100%;
        top: -12px 
    }
    .submit-row {
        width: 100%;
        padding-top: 5%;
    }
    .submission-status {
        display: none;
    }
    .subject-row {
        padding-top: 10%;
        width: 100%;
    }
    .subject-field {
        width: 75%;
        left: 10px;
    }
    .message-field {
        width: 75%;
        height: 100%;
        left: 10px;
    }
    .contact-us {
        height: 1000px; 
     }
}
