.intro-and-icon-row-desktop {
    display: flex;
    text-align: center;
    padding-top: 5%;
}
.intro-and-icon-col-desktop {
    display: flex;
    align-items: center;
    margin: auto;
    animation-name: wipe;
    animation-duration: 4s;
}
.intro-row-mobile {
    display: none;
}
.icon-row-mobile {
    display: none;
}
@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Raleway:800,500,500italic,900");
.raleway-black-port-gore-100px {
    color: var(--port-gore);
    font-family: var(--font-family-raleway);
    font-size: 100px;
    font-style: normal;
    font-weight: 900;
    animation-name: reveal;
    animation-duration: 4.5s;
}
.span0-row {
    padding-top: 2%;
    display: flex;
    justify-content: center;
    animation-name: wipe;
    animation-duration: 4s;
}
.span0-col {
    text-align: center;
}
.span0 {
    color: rgba(34,31,75,1.0);
    font-family: 'Roboto', Helvetica;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: -0.36px;;
}
.tools-icon {
    color: var(--port-gore);
    font-family: 'Font Awesome 5 Free-Solid', Helvetica;
    font-size: 96px;
    letter-spacing: -3.36px;
    margin-left: 7px;
    margin-top: 3px;
    min-height: 110px;
    text-align: center;
    width: 128px;
    animation-name: drop;
    animation-duration: 2s;
    animation-delay: 4.5s;
}


@media only screen and (max-width: 600px) {
    .intro-and-icon-row-desktop {
        display: none;
    }
    .intro-row-mobile {
        display: inline;
        padding-top: 4%;
        
    }
    .intro-col-mobile {
        margin: auto;
        display: inline;
        width: 80vw;
    }
    .icon-row-mobile {
        display: inline;
        padding-top: 1.5%;
    }
    .intro-text {
        align-content: center;
    }
    .our-tools-intro {
        align-items: flex-start;
        display: flex;
    }
    .span0-row {
        padding-top: 0%;
        margin: 0%;
    }
    .span0-col {
        width: 360px;
    }
    .span0 {
        color: rgba(34,31,75,1.0);
        font-family: 'Raleway', Helvetica;
        font-size: 24px;
        font-weight: 500;
        letter-spacing: -0.36px;
        text-align: center;
    }
    .title {
        letter-spacing: -3.30px;
        /* min-height: 124px; */
        /* text-align: center; */
    }
    .text-1 {
        /* letter-spacing: -1.12px;
        margin-top: 20px;
        margin-left: 5vw;
        min-height: 69px;
        text-align: center;
        width: 50vw; */
    }
    .tools-icon-mobile {
        color: var(--port-gore);
        font-family: 'Font Awesome 5 Free-Solid', Helvetica;
        font-size: 68px;
        letter-spacing: -3.36px;
        margin: auto;
        padding-top: 1%;
        min-height: 110px;
        text-align: center;
        width: 128px;
    }
    @import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
    @import url("https://fonts.googleapis.com/css?family=Raleway:800,500,500italic,900");
    .raleway-black-port-gore-100px {
        color: var(--port-gore);
        font-family: var(--font-family-raleway);
        font-size: 80px;
        font-style: normal;
        font-weight: 900;
    }
}
@keyframes drop {
    0%, 20%, 50%, 80%, 90% {transform: translateY(0);} 
   40% {transform: translateY(-40px);} 
   60% {transform: translateY(-30px);}
}
@keyframes wipe {
    from {
        opacity: 0;
    }
}

