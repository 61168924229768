.intro-and-icon-row-desktop {
    display: flex;
    text-align: center;
    padding-top: 5%;
}
.intro-and-icon-col-desktop {
    display: flex;
    align-items: center;
    margin: auto;
    animation: none;
}
.intro-row-mobile {
    display: none;
}
.icon-row-mobile {
    display: none;
}
@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Raleway:800,500,500italic,900");

.search-icon {
    color: var(--port-gore);
    font-family: 'Font Awesome 5 Free-Solid', Helvetica;
    font-size: 96px;
    letter-spacing: -3.36px;
    margin-left: 7px;
    margin-top: 3px;
    min-height: 110px;
    text-align: center;
    width: 128px;
}
.sub-text {
    margin-top: 15px;
}
.sub-text-mobile {
    display: none;
}
@media only screen and (max-width: 600px) {
    .intro-and-icon-row-desktop {
        display: none;
    }
    .intro-row-mobile {
        display: inline;
        align-content: center!important;
        margin-top: 8%;
    }
    .category-col-mobile {
        width: 100%!important;
    }
    .copy {
        display: none;
    }
    .icon-row-mobile {
        display: inline;
        padding-top: 1.5%;
    }
    .intro-text {
        align-content: center;
    }
    .sub-text {
        display: none;
    }
    .sub-text-mobile {
        position: relative;
        width: 100%;
        display: inline;
        text-align: center;
    }
    .category-title {
        display: inline;
        font-size: 15vw;
        align-self: flex-end;
        letter-spacing: -1.47px;
        min-height: 49px;
        text-align: center;
        width: 80vw;
    }
    .fundraising-mobile {
        width: 100%;
        font-size: 14vw!important;
        align-self: center;
    }
    .mobilization-mobile {
        width: 100%;
        font-size: 13vw!important;
        align-self: center;
    }
}
