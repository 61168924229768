.vendorbox {
    width: 380px;
    height: 320px;
    /* margin-bottom: 1000px; */
}
.tagline {
    text-align: center;
    overflow-y: auto;
}
.card-img {
    width: 100%;
    object-fit: fill;
    vertical-align: center;
}
.img-container {
    display: relative;
    margin: auto;
    width: auto;
    height: 150px;
}
@media only screen and (max-width: 600px) {
    .vendorbox {
        width: 80vw;
        /* margin-bottom: 1000px; */
    }
}