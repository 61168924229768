.intro-and-icon-row-desktop {
    display: flex;
    text-align: center;
    padding-top: 5%;
}
.intro-and-icon-col-desktop {
    display: flex;
    align-items: center;
    margin: auto;
    animation: none;
}
.intro-row-mobile {
    display: none;
}
.icon-row-mobile {
    display: none;
}
@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Raleway:800,500,500italic,900");

.search-icon {
    color: var(--port-gore);
    font-family: 'Font Awesome 5 Free-Solid', Helvetica;
    font-size: 96px;
    letter-spacing: -3.36px;
    margin-left: 7px;
    margin-top: 3px;
    min-height: 110px;
    text-align: center;
    width: 128px;
}


@media only screen and (max-width: 600px) {
    .intro-and-icon-row-desktop {
        display: none;
    }
    .intro-row-mobile {
        display: flex;
        padding-top: 4%;
    }
    .intro-col-mobile {
        margin: auto;
        width: 250px;
    }
    .icon-row-mobile {
        display: flex;
        padding-top: 1.5%;
    }
    .intro-text {
        align-content: center;
    }
    .our-tools-intro {
        align-items: flex-start;
        display: flex;
    }
    @import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
    @import url("https://fonts.googleapis.com/css?family=Raleway:800,500,500italic,900");
}
