.modal {
    display: 'flex';
    align-items: 'center'; 
    justify-content: 'center';
}
.paper {
    background-color: white;
    border-radius: 25px;
    width: 70vh;
    border: 2px solid #221f4b;
    box-shadow: 0;
    padding: 2, 4, 3;
}
.banner {
    width: 100%;
    display: block;
    text-align: center;
}