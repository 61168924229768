.navbar-logo {
    padding: 15px;
}

.navbar-icon {
    height: 100px;
}

.navbar-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border: 1px solid #2c2a2a;
    background-color: rgba(34, 31, 75, 1);
}

li {
    float: left;
}

.login-btn {
    padding-right: 30px;
    padding-top: 45px;
    justify-content: end;
    float: right;
}

.menu {
    justify-content: right;
}