/* Changelog: Jim - changed height to 100% */
.landing-page {
  background-image: url("./bbtbackground-767px.jpg");
  background-color: #cccccc;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  width: auto;
  min-height: 431px;
}

.animated-text-row {
  display: inline;
  width: 100%;
}

.kristi-regular-165px{
  color:#FFDB07;
  font-family: 'Kristi', cursive;
  font-style: regular;
  font-size: 100px;
  line-height: 100px;
}

.raleway-extra-bold-white-84px {
  color: var(--white);
  font-family: var(--font-family-raleway);
  font-size: 84px;
  font-style: normal;
  font-weight: bold;
}

.raleway-medium-white-32px {
  width: 800px;
}

/* Yellow Text start */

.yellow-text-one {
  color: #FFDB07;
  font-weight: bold;
}

.yellow-text-two {
  color: #FFDB07;
  font-weight: bold;
}

.yellow-text-three {
  color: #FFDB07;
  font-weight: bold;
}

/* Yellow Text end */

.subtext-row {
  padding-top: 40px;
  animation-name: fadeinup;
  animation-duration: 2s;
}
.subtext-row-2 {
  padding-top: 40px;
  animation-name: fadeinup;
  animation-duration: 3s;
}
.wheel-text-row {
  padding-top: 150px;
  animation-name: fadeinup;
  animation-duration: 1s;
}
p {
  display: block;
  text-align: left;
}
.button-row {
  padding-top: 60px;
  display: flex;
  justify-content: left;
  animation-name: fadeinup;
  animation-duration: 4s;
}
.drop-shadow {
  filter: drop-shadow(16px 20px 16px black);
}
.bb-btn {
  padding: 15px 30px 15px;
  font-size: 22px;
  font-family: var(--font-family-raleway);
  font-weight: bold;
  color: #ffffff;
  background-color: linear-gradient(rgb(170, 148, 23), rgb(223, 205, 48));
  border-radius: 3px;
  background-image: linear-gradient(
    to right,
    #5587e4 0%,
    #3b7cd1 51%,
    #5a89e0 100%
  );
  box-shadow: "0 3px 5px 2px rgba(80,80,80, .3)";
  /* text-transform: uppercase; */
}
.bb-btn:hover {
  background: "#eab256";
  opacity: 0.9;
}
.innovate-col {
  display: inline-flex;
  padding-top: 5%;
}

@media only screen and (max-width: 1220px) {
  .innovate-col {
    display: none;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .col-xl-12 {
    width: 100%;
  }
.landing-page {
  background-image: url("./bbtbackground-1920px.jpg");
  background-color: #cccccc;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: auto;
  width: auto;
  min-height: 1080px;
}
}

/* X Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399px) {
  .col-xl-12 {
    width: 100%;
  }
.landing-page {
  background-image: url("./bbtbackground-1399px.jpg");
  background-color: #cccccc;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: auto;
  width: auto;
  min-height: 787px;
}
}


/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199px) {
  .col-lg-12 {
    width: 100%;
  }
  .landing-page {
    background-image: url("./bbtbackground-1199px.jpg");
    background-color: #cccccc;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: auto;
    width: auto;
    min-height: 674px;
  }
 }

 /* Medium devices (tablets, 768px and up) */
 @media (min-width: 768px) and (max-width: 991px) {
  .col-md-12 {
    width: 100%;
  }
  .landing-page {
    background-image: url("./bbtbackground-991px.jpg");
    background-color: #cccccc;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: auto;
    width: auto;
    min-height: 557px;
  }
 }



   /* Small devices (landscape phones, 576px and up) */
   @media (min-width: 576x) and (max-width: 767px) {
    .col-sm-12 {
      width: 100%;
    }
  }
