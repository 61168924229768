.results {
    margin: 5%;
    min-height: 1200px;
}
.result-card {
    padding-top: 15px;
}
.result-text {
    margin-top: 15px;
}
.search-field {
    margin-top: 50px;
    width: 800px;
    animation: none;
}

@media only screen and (max-width: 600px) {
    .results {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .search-field {
        margin-top: 50px;
        width: 80vw;
        animation: none;
    }
    .result-text {
        margin-bottom: 10%;
    }
}