.container-landing {
  display: grid;
  padding: 3px;
  margin: 3px;
  width: 100%;
}

.kristi-regular-165px {
  font-size:  2.0em;
  margin-top: 3px;
 }

.raleway-white-84px {
  font-size:  2.3em;
  margin-top: 3px;
  
  
}
.subtext-1 {
  font-size:  1.1em;
  line-height: 17px;
  margin-top: 2px;
  filter: drop-shadow(10px 1px 4px #000000);
  width: 70%;
  
}
  
.subtext-2 {
  font-size:  1.1em;
  line-height: 17x;
  margin-top: 2px;
  filter: drop-shadow(10px 1px 4px #000000);
  width: 70%;
  
  }
  
.bb-btn {
  padding: 10px 24px 10px;
  font-size: 15px;
  margin-top: -35px;
  }
  

.animated-text-col:hover {
  cursor: default;
}

.button-col {
  padding-top: 25px;
  padding-bottom: 10px;
  float: left;

}
.landing-page-icon {
  height: 80vh;
  /* margin-right: 800px */
}
/* Changelog: Jim */
.landing-page-text-1 {
  margin-top: 15px;
}
/* Changelog: Jim */
.landing-page-text-2 {
  margin-top: 15px;
  margin-bottom: 30px;
}

.subtext-1 {
  text-align: left;
  padding-top: 2px;
}

.subtext-2 {
  text-align: left;
  padding-top: 2px;
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .container-landing {
    display: grid;
    padding: 50px;
    margin: 50px;
    width: 100%;
  }
  
    .kristi-regular-165px {
      font-size:  2.5em;
      margin-top: 15px;
    
  
    }
    .raleway-white-84px {
      font-size:  5.6em;
      margin-top: 30px;
  
  
  }
  .subtext-1 {
    font-size:  2.2em;
    line-height: 35px;
    margin-top: 90px;
    filter: drop-shadow(10px 1px 4px #000000);
    width: 60%;
  
  }
  
  .subtext-2 {
  font-size:  2.2em;
  line-height: 35px;
  margin-top: 70px;
  filter: drop-shadow(10px 1px 4px #000000);
  width: 60%;
  
  }
  
  .bb-btn {
    padding: 18px 35px 18px;
    font-size: 30px;
    margin-top: 20px;
  }

 }

 /* X Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399px) {
  
  .container-landing {
    display: grid;
    padding: 20px;
    margin: 20px;
    width: 100%;
  }
  
    .kristi-regular-165px {
      font-size:  2.3em;
      margin-top: 15px;
    
  
    }
    .raleway-white-84px {
      font-size:  4.6em;
      margin-top: 30px;
  
  
  }
  .subtext-1 {
    font-size:  1.9em;
    line-height: 33px;
    margin-top: 60px;
    filter: drop-shadow(10px 1px 4px #000000);
    width: 60%;
  
  }
  
  .subtext-2 {
  font-size:  1.9em;
  line-height: 33px;
  margin-top: 40px;
  filter: drop-shadow(10px 1px 4px #000000);
  width: 60%;
  
  }
  
  .bb-btn {
    padding: 12px 25px 12px;
    font-size: 25px;
    margin-top: -10px;
  }
  
   }


 /* Large devices (desktops, 992px and up) */
 @media (min-width: 992px) and (max-width: 1199px) {

.container-landing {
  display: grid;
  padding: 10px;
  margin: 10px;
  width: 100%;
}

  .kristi-regular-165px {
    font-size:  2.25em;
    margin-top: 15px;
  

  }
  .raleway-white-84px {
    font-size:  4.5em;
    margin-top: 30px;


}
.subtext-1 {
  font-size:  1.8em;
  line-height: 32px;
  margin-top: 40px;
  filter: drop-shadow(10px 1px 4px #000000);
  width: 60%;

}

.subtext-2 {
font-size:  1.8em;
line-height: 32px;
margin-top: 40px;
filter: drop-shadow(10px 1px 4px #000000);
width: 60%;

}

.bb-btn {
  padding: 12px 23px 12px;
  font-size: 20px;
  margin-top: -20px;
}

 }

/* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991px) {
  .container-landing {
    display: grid;
    padding: 10px;
    margin: 10px;
    width: 100%;
    }
    
  .kristi-regular-165px {
    font-size:  2em;
    margin-top: 10px;
   }

  .raleway-white-84px {
    font-size:  4em;
    margin-top: 10px;
    
    
  }
  .subtext-1 {
    font-size:  1.6em;
    line-height: 27px;
    margin-top: 20px;
    padding-top: 10px;
    filter: drop-shadow(10px 1px 4px #000000);
    width: 63%;
    
  }
    
  .subtext-2 {
    font-size:  1.6em;
    line-height: 27x;
    margin-top: 20px;
    padding-top: 10px;
    filter: drop-shadow(10px 1px 4px #000000);
    width: 63%;
    
    }
    
  .bb-btn {
    padding: 10px 24px 10px;
    font-size: 18px;
    margin-top: -5px;
    }
    
     }
