.loading {
    display: flex;
    width: auto;
    height: 80vh;
    justify-content: center;
    align-content: center;
}
.progress {
    position: absolute;
    top: 50%;
    background-color:transparent;
}