.all-blocks {
  margin: auto;
  margin-top: 5%;
  padding-bottom: 10%;
  display: flex;
  justify-content: center;
}
.analyze-news {
  letter-spacing: 0.00px;
  margin-left: 2.0px;
  margin-top: 14px;
  min-height: 20px;
  text-align: center;
  width: 391px;
}
.build-blue-tools-logo-footer {
  align-self: flex-start;
  height: 148px;
  object-fit: cover;
  width: 137px;
}
.build-blue-tools-logo-header {
  height: 221px;
  object-fit: cover;
  width: 205px;
}
.campaign-staff {
  letter-spacing: 0.00px;
  margin-left: 2.0px;
  margin-top: 22px;
  min-height: 20px;
  text-align: center;
  width: 293px;
}
.conduct-a-poll {
  letter-spacing: 0.00px;
  margin-left: 2.0px;
  margin-top: 14px;
  min-height: 20px;
  text-align: center;
  width: 323px;
}
.copyright {
  letter-spacing: -0.55px;
  margin-right: 12.0px;
  margin-top: 28px;
  min-height: 26px;
  min-width: 232px;
  text-align: center;
}
.create-graphics {
  letter-spacing: 0.00px;
  margin-left: 2.0px;
  margin-top: 14px;
  min-height: 20px;
  text-align: center;
  width: 303px;
}
.data-research {
  letter-spacing: -1.47px;
  margin-left: 2.0px;
  margin-top: 18px;
  min-height: 49px;
  text-align: center;
  width: 409px;
}
.data-research-block {
  align-items: center;
  background-color: var(--havelock-blue);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 263px;
  margin-left: 1px;
  margin-top: 20px;
  overflow: hidden;
  padding: 22px 0;
  width: 407px;
}
.digital-ads {
  letter-spacing: -1.47px;
  margin-left: 3.0px;
  margin-top: 18px;
  min-height: 49px;
  text-align: center;
  width: 372px;
}
.digital-ads-block {
  align-items: center;
  background-color: var(--havelock-blue);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 265px;
  margin-top: 20px;
  overflow: hidden;
  padding: 22px 0;
  width: 407px;
}
.digital-ads-icon {
  letter-spacing: -1.85px;
  margin-left: 2.0px;
  min-height: 61px;
  text-align: center;
  width: 267px;
}
.digital-content {
  letter-spacing: -1.47px;
  margin-left: 2.0px;
  margin-top: 18px;
  min-height: 49px;
  text-align: center;
  width: 401px;
}
.digital-content-block {
  align-items: center;
  background-color: var(--port-gore);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 299px;
  margin-left: 1px;
  overflow: hidden;
  padding: 22px 2px;
  width: 407px;
}
.digital-testing {
  align-self: flex-end;
  letter-spacing: 0.00px;
  margin-top: 22px;
  min-height: 20px;
  text-align: center;
  width: 355px;
}
.e-mail {
  letter-spacing: -1.47px;
  margin-left: 1.0px;
  margin-top: 23px;
  min-height: 49px;
  text-align: center;
  width: auto;
}
.e-mail-block {
  align-items: center;
  background-color: var(--port-gore);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 335px;
  overflow: hidden;
  padding: 21px 8px;
  width: 407px;
}
.e-mail-testing {
  letter-spacing: 0.00px;
  margin-left: 3.0px;
  margin-top: 14px;
  min-height: 20px;
  text-align: center;
  width: 326px;
}
.find-talent {
  align-self: flex-end;
  letter-spacing: -1.47px;
  margin-top: 18px;
  min-height: 49px;
  text-align: center;
  width: 360px;
}
.find-talent-block {
  align-items: center;
  background-color: var(--port-gore);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 330px;
  margin-top: 22px;
  overflow: hidden;
  padding: 22px;
  width: 407px;
}
.flex-col-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 450px;
  animation-name: fadeinup;
  animation-duration: 2s;
}
.flex-col-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 450px;
  animation-name: fadeinup;
  animation-duration: 3s;
}
.flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 450px;
  animation-name: fadeinup;
  animation-duration: 4s;
}
.footer {
  align-items: center;
  background-color: var(--port-gore);
  display: flex;
  height: 200px;
  margin-right: 1.0px;
  margin-top: 102px;
  padding: 20px 86px;
  width: 1441px;
}
.footer-button {
  align-items: flex-start;
  display: flex;
  margin-left: 138px;
  margin-top: 3.0px;
  min-width: 314px;
}
.fundraising {
  align-self: flex-end;
  letter-spacing: -1.47px;
  margin-top: 18px;
  min-height: 49px;
  text-align: center;
  width: 372px;
}
.fundraising-block {
  align-items: center;
  background-color: var(--havelock-blue);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 265px;
  margin-top: 19px;
  overflow: hidden;
  padding: 22px 16px;
  width: 407px;
}
.fundraising-icon {
  letter-spacing: -1.85px;
  margin-left: 2.0px;
  min-height: 61px;
  text-align: center;
  width: 205px;
}
.grow-my-list {
  letter-spacing: 0.00px;
  margin-left: 1.0px;
  margin-top: 23px;
  min-height: 20px;
  text-align: center;
  width: 304px;
}
.header {
  align-items: flex-start;
  background-color: var(--port-gore);
  display: flex;
  height: 262px;
  justify-content: flex-end;
  min-width: 1440px;
  padding: 16px 89px;
}
.header-button {
  align-items: center;
  align-self: center;
  background-color: var(--porsche);
  border-radius: 8px;
  display: flex;
  height: 82px;
  justify-content: flex-end;
  margin-left: 705px;
  min-width: 350px;
  padding: 0 36px;
}
.homepage {
  padding: 0%;
}
.icon {
  letter-spacing: -1.85px;
  margin-left: 1.0px;
  min-height: 61px;
  text-align: center;
  width: 190px;
}
.learn-more {
  letter-spacing: -1.05px;
  min-height: 35px;
  min-width: 185px;
  text-align: center;
}
.login-or-sign-up {
  letter-spacing: -1.12px;
  min-height: 38px;
  min-width: 276px;
  text-align: center;
}
.mobilization {
  letter-spacing: -1.47px;
  margin-left: 2.0px;
  margin-top: 18px;
  min-height: 49px;
  text-align: center;
  width: 409px;
}
.mobilization-block {
  align-items: center;
  background-color: var(--port-gore);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 301px;
  margin-top: 20px;
  overflow: hidden;
  padding: 22px 0;
  width: 407px;
}
.mobilization-icon {
  letter-spacing: -1.85px;
  margin-left: 2.0px;
  min-height: 61px;
  text-align: center;
  width: 291px;
}
.overlap-group {
  align-items: center;
  background-color: var(--porsche);
  border-radius: 8px;
  display: flex;
  height: 77px;
  justify-content: flex-end;
  min-width: 312px;
  padding: 0 62px;
}
.paint-brush-icon {
  letter-spacing: -1.85px;
  margin-left: 2.0px;
  min-height: 61px;
  text-align: center;
  width: 231px;
}
.phones {
  letter-spacing: -1.47px;
  margin-left: 2.0px;
  margin-top: 18px;
  min-height: 49px;
  text-align: center;
  width: 313px;
}
.phones-block {
  align-items: center;
  background-color: var(--havelock-blue);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 263px;
  overflow: hidden;
  padding: 22px 0;
  width: 407px;
}
.phones-icon {
  letter-spacing: -1.85px;
  min-height: 61px;
  text-align: center;
  width: 181px;
}
.run-calltime {
  letter-spacing: 0.00px;
  margin-left: 3.0px;
  margin-top: 22px;
  min-height: 20px;
  text-align: center;
  width: 322px;
}
.talent-icon {
  letter-spacing: -1.85px;
  margin-left: 2.0px;
  min-height: 61px;
  text-align: center;
  width: 293px;
}
.testing {
  letter-spacing: -1.47px;
  margin-left: 3.0px;
  margin-top: 18px;
  min-height: 49px;
  text-align: center;
  width: 328px;
}
.testing-block {
  align-items: center;
  background-color: var(--port-gore);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 260px;
  margin-top: 22px;
  overflow: hidden;
  padding: 22px 25px;
  width: 407px;
}
.testing-icon {
  letter-spacing: -1.85px;
  margin-left: 3.0px;
  min-height: 61px;
  text-align: center;
  width: 270px;
}
.text- {
  letter-spacing: 0.00px;
  margin-top: 15px;
  min-height: 20px;
  text-align: center;
  width: 357px;
}
.text-2-mobile {
  display: none;
}
.text-1-1 {
  letter-spacing: 0.00px;
  margin-left: 2.0px;
  margin-top: 14px;
  min-height: 20px;
  text-align: center;
  width: 409px;
}
.text-10 {
  letter-spacing: 0.00px;
  margin-left: 2.0px;
  margin-top: 14px;
  min-height: 20px;
  text-align: center;
  width: 331px;
}
.text-11 {
  letter-spacing: 0.00px;
  margin-left: 2.0px;
  margin-top: 14px;
  min-height: 20px;
  text-align: center;
  width: 351px;
}
.text-12 {
  letter-spacing: -1.85px;
  margin-left: 2.0px;
  min-height: 61px;
  text-align: center;
  width: 289px;
}
.text-13 {
  letter-spacing: 0.00px;
  margin-left: 2.0px;
  margin-top: 22px;
  min-height: 20px;
  text-align: center;
  width: 357px;
}
.text-14 {
  letter-spacing: 0.00px;
  margin-left: 2.0px;
  margin-top: 22px;
  min-height: 20px;
  text-align: center;
  width: 391px;
}
.text-16 {
  letter-spacing: 0.00px;
  margin-right: 5.0px;
  margin-top: 22px;
  min-height: 20px;
  text-align: center;
  width: 402px;
}
.text-17 {
  letter-spacing: 0.00px;
  margin-right: 5.0px;
  margin-top: 14px;
  min-height: 20px;
  text-align: center;
  width: 402px;
}
.text-2 {
  align-self: flex-end;
  letter-spacing: 0.00px;
  margin-top: 15px;
  min-height: 20px;
  text-align: center;
  width: 390px;
}
.text-4 {
  letter-spacing: 0.00px;
  margin-left: 1.0px;
  margin-top: 16px;
  min-height: 20px;
  text-align: center;
  width: 374px;
}
.text-6 {
  letter-spacing: 0.00px;
  margin-left: 2.0px;
  margin-top: 22px;
  min-height: 20px;
  text-align: center;
  width: 321px;
}
.text-7 {
  letter-spacing: 0.00px;
  margin-top: 14px;
  min-height: 20px;
  text-align: center;
  width: 345px;
}
.text-8 {
  letter-spacing: 0.00px;
  margin-left: 2.0px;
  margin-top: 22px;
  min-height: 20px;
  text-align: center;
  width: 331px;
}
.text-9 {
  letter-spacing: 0.00px;
  margin-left: 2.0px;
  margin-top: 14px;
  min-height: 20px;
  text-align: center;
  width: 353px;
}
.search-field-home {
  margin-top: 50px;
  width: 800px;
  animation-name: fadeinup;
  animation-duration: 2s;
}

span:hover {
  cursor:pointer;
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  .all-blocks {
    flex-direction: column;
    width: auto;
    justify-content: center;
  }
  .e-mail-block {
    width: 90%;
  }
  .fundraising-block {
    width: 90%;
  }
  .fundraising {
    align-self: flex-end;
    letter-spacing: -1.47px;
    min-height: 49px;
    text-align: center;
    width: 300px;
  }
  .find-talent {
    width: 280px;
  }
  .testing-block {
    width: 90%;
  }
  .phones-block {
    width: 90%;
  }
  .find-talent-block {
    width: 90%;
  }
  .digital-ads-block {
    width: 90%;
  }
  .digital-content-block {
    width: 90%;
  }
  .digital-content {
    width: 90%;
    margin-bottom: 0%;
  }
  .digital-testing {
    width: 100%;
  }
  .data-research-block {
    width: 90%;
    height: 280px;
  }
  .data-research {
    width: 280px;
    margin-bottom: 8%;
  }
  .mobilization-block {
    width: 90%;
  }
  .flex-col {
    padding-left: 5%;
    padding-top: 4%;
    padding-right: 0;
    width: auto;
  }
  .flex-col-1 {
    padding-left: 5%;
    padding-right: 0;
    width: auto;
  }
  .flex-col-2 {
    padding-left: 5%;
    padding-top: 4%;
    padding-right: 0;
    width: auto;
  }
  .search-field-home {
    margin-top: 50px;
    width: 90vw;
    animation-name: fadeinup;
    animation-duration: 2s;
  }
  .text-10 {
    width: 80%;
  }
}
@keyframes fadeinup {
  from {
    transform: translateY(500px);
    opacity: .1;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
