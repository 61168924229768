.policy-text-row {
    padding-left: 10vw;
    padding-right: 13vw;
    text-align: left;
}
ul {
    padding-left: 30px;
    display: block;
}
.privacy-li {
    margin-bottom: 8px;
    padding-left: 10px;
    display: list-item;
    float: none;
    list-style-type: square;
}
ol {
    margin-left: 2vw;
}